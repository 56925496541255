<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <!-- <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search exercises"
          type="text"
        />
        <b-button
          v-b-modal.applicationModal
          variant="primary mr-1"
        >
          <feather-icon icon="PlusIcon" />
          Add Exercise
        </b-button>
        <b-button
          v-if="$route.params.wid"
          variant="outline-primary mr-1"
          @click="$router.replace(`/programs/${$route.params.id}/manage#Exercises`)"
        >
          Show All
        </b-button>
      </b-form-group>
    </div> -->

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'title'"
          class="d-flex justify-content-between"
        >
          {{ props.formattedRow[props.column.field] }}
          <b-button
            size="sm"
            variant="outline-primary"
            :to="{name: 'review-questions',
                  params: {pid: $route.params.id,
                           apid: props.row.id}}"
          >
            <span>View</span>
          </b-button>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            :variant="statusVariant(props.formattedRow[props.column.field])"
            style="text-transform: capitalize;"
          >
            {{ props.formattedRow[props.column.field] || 'Draft' }}
          </b-badge><br>
          <b-form-checkbox
            v-b-tooltip="props.row.status==='enabled' ? 'Submissions Enabled' : 'Submissions Disabled'"
            :checked="props.row.status==='enabled'"
            class="mt-50 custom-control-primary"
            name="check-button"
            switch
            @change="updateAssignmentStatus(props.row.status, props.row.id, `status`)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </span>
        <span v-else-if="props.column.field === 'review_status'">
          <b-badge
            :variant="statusVariant(props.formattedRow[props.column.field])"
            style="text-transform: capitalize;"
          >
            {{ props.formattedRow[props.column.field] || 'Draft' }}
          </b-badge><br>
          <b-form-checkbox
            v-b-tooltip="props.row.review_status==='enabled' ? 'Evaluation Enabled' : 'Evaluation Disabled'"
            :checked="props.row.review_status==='enabled'"
            class="mt-50 custom-control-primary"
            name="check-button"
            switch
            @change="updateAssignmentStatus(props.row.review_status, props.row.id, `review_status`)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </span>
        <span v-else-if="props.column.field === 'operation_title'">
          <b-badge
            variant="light-primary"
            class="mr-50"
          >{{ props.row.operation_type }}</b-badge> {{ props.formattedRow[props.column.field] }}

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="applicationModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Review Form"
      @ok="addReview"
    >
      <b-row>
        <b-col md="5">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="reviewTitle"
              placeholder="Review Title"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type"
            label-size="sm"
          >
            <b-form-input
              v-model="reviewType"
              placeholder="Review Type"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Operation"
            label-size="sm"
          >
            <treeselect
              v-model="selectedOperation"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <form-builder
        v-model="applicationForm"
      />
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    FormBuilder,
    // vSelect,
    Treeselect,
  },
  data() {
    return {
      mutationLoading: false,
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Exercise',
          field: 'title',
          width: '30%',
        },
        {
          label: 'Operation',
          field: row => row.e.title,
        },
        {
          label: 'Submission',
          field: 'status',
          tdClass: 'text-center',
        },
        {
          label: 'Evaluation',
          field: 'review_status',
          tdClass: 'text-center',
        },
      ],
      operations: [],
      reviewTitle: null,
      selectedOperation: null,
      reviewType: null,
    }
  },
  computed: {
    rows() {
      const reviews = []
      // console.log(this.operations)
      this.operations.map(e => {
        e.programs_assignmenttables.map(a => {
          reviews.push({
            e,
            ...a,
          })
          return 0
        })
        return 0
      })
      return reviews
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addReview() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.reviewTitle}",type: "${this.reviewType}", status: "draft", review_status: "draft", operations_id: ${this.selectedOperation}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: (store, { data: { insert_programs_assignmenttable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_assignmenttable_one.id ? 'Review added successfully' : 'Failed to create review',
                icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.operations.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    operations: {
      query() {
        return gql`{
          programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: ${this.$route.params.id}} ${this.$route.params.wid ? `, id: {_eq: ${this.$route.params.wid}}` : ''}}) {
            title
            id
            programs_assignmenttables {
              id
              status
              review_status
              title
            }
            operations_type
            programs_operationstable {
              id
              operations_type
            }
          }
        }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
